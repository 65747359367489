import React from 'react';
import './about.css';
import ME from '../../assets/me-about1.jpg';
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {FiFolder} from 'react-icons/fi'


const About = () => {
  return (
    <section id='about'>
      <h5> Get to Know</h5>
      <h2> About Me</h2>

      <div className="container about__container" >
      <div className="about__me">
        <div className="about__me-image">

          <img src={ME} alt='About' />
        </div>

        
      </div>
      <div className="about__content">
        <div className="about__cards">
          <article className="about__card">
          <FaAward className='about__icon'/>
            <h5>Experience</h5>
            <small>1 year+</small>

          </article>
          <article className="about__card">
          <FiUsers className='about__icon'/>
            <h5>Clients</h5>
            <small>5+</small>

          </article>
          <article className="about__card">
          <FiFolder className='about__icon'/>
            <h5>Projects</h5>
            <small>10+</small>

          </article>
        </div>
        <p> 
        Welcome to my portfolio website! I am a dedicated and passionate frontend enthusiast committed to crafting visually stunning and user-friendly digital experiences. With expertise in frontend technologies such as HTML, CSS, JavaScript, and React, I possess the skills to bring your ideas to life in the most engaging and interactive manner. 
        </p>
        <p>
        Let's collaborate and turn your vision into a reality that captivates and delights your audience.
        </p>

        <a href="#contact" className='btn btn-primary'> Let's Talk</a>

      </div>
      </div>      
    </section>
  )
}

export default About