import React from 'react';
import {ImLinkedin} from 'react-icons/im'
import {BsGithub} from 'react-icons/bs'
import {BsTwitter} from 'react-icons/bs'
import {BsInstagram} from 'react-icons/bs'
import {BsTelegram} from 'react-icons/bs'

const HeaderSocials = () => {
  return (
    <div className = 'header__socials'>
    <a href="https://www.linkedin.com/in/sudip-parajuli-18346a1b1/" target = "_blank"> <ImLinkedin/></a>
    <a href="https://github.com/Sudip777" target = "_blank"> <BsGithub/></a>
    <a href="https://twitter.com/sudipparajuli10" target = "_blank"> <BsTwitter/></a>
    <a href="https://www.instagram.com/sudiparajuli.me/?hl=en" target = "_blank"> <BsInstagram/></a>
    <a href="https://t.me/sudipppppppppppppppp" target = "_blank"> <BsTelegram/></a>
    </div>
   
  )
}

export default HeaderSocials