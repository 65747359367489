import React from 'react';
import './nav.css';
import {AiOutlineHome} from 'react-icons/ai'
import {AiOutlineUser} from 'react-icons/ai'
import {BiBookAlt} from 'react-icons/bi'
//import {MdOutlineCleaningServices} from 'react-icons/md' 
import {RiContactsBookLine} from 'react-icons/ri'
import {BsBagCheck} from 'react-icons/bs'
import {useState} from 'react'


const Nav = () => {
  const [activeNav, setActiveNav] = useState("#");
  return (
    <nav>
      <a href='#' onClick={()=>setActiveNav('#')} className={activeNav ==='#'?'active': ""}> <AiOutlineHome/> </a>
      <a href='#about' onClick={()=>setActiveNav('#about')} className={activeNav==='#about'?'active':""}> <AiOutlineUser/></a>
      <a href='#experience'onClick={()=>setActiveNav('#experience')} className={activeNav==='#experience'?'active':""}> <BiBookAlt/></a>
     {/* <a href='#services' onClick={()=>setActiveNav('#services')} className={activeNav==='#services'?'active':""}> <MdOutlineCleaningServices/></a> */}
      <a href="#portfolio" onClick={()=>setActiveNav('#portfolio')} className={activeNav==='#portfolio'?'active':""}> <BsBagCheck/> </a>
      <a href='#contact' onClick={()=>setActiveNav('#contact')} className={activeNav==='#contact'?'active':""}> <RiContactsBookLine/></a>
    </nav>
  )
}

export default Nav