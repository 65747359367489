import React from 'react';
import './footer.css';
import {BsInstagram} from 'react-icons/bs'
import {BsFacebook} from 'react-icons/bs'
import {BsTwitter} from 'react-icons/bs'



const Footer = () => {
  return (
      
        <footer id='footer'>
          <a href="#" className="footer__logo">SUDIP</a>
          <ul className='permalinks'>
            <li><a href="#">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#experience">Experience</a></li>
           {/*} <li><a href="#services">Services</a></li> */}
            <li><a href="#portfolio">Portfolio</a></li>
           {/* <li><a href="#testimonials">Testimonials</a></li> */}
            <li><a href="#contact">Contact</a></li>
          </ul>
          <div className="footer__socials">
            <a href="https://www.facebook.com/profile.php?id=100072541352569"><BsFacebook/></a>
            <a href="https://www.instagram.com/sudiparajuli.me/?hl=en"> <BsInstagram/></a>
            <a href="https://twitter.com/sudipparajuli10"> <BsTwitter/></a>
          </div>
        <div className="footer__copyright">
          <small> &copy; Sudip Parajuli. All rights reserved. </small>
        </div>
        </footer>

  )
}

export default Footer