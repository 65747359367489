import React from 'react';
import './contact.css';
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {TbBrandTelegram} from 'react-icons/tb'
import { useRef } from 'react';
import emailjs from 'emailjs-com'

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();


    emailjs.sendForm('service_a03qlxk', 'template_8m50nmo', form.current, 'MlaVZ-zOZZwCcy1o4');
    e.target.reset();
};


  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>
              sudipparajuli06@gmail.com
            </h5>
            <a href="mailto:sudipparajuli06@gmail.com" target="_blank">Send an Email</a>
          </article>
          <article className="contact__option">
            <RiMessengerLine className='contact__option-icon'/>
            <h4>Messenger</h4>
            <h5>
             sudipparajuli.me
            </h5>
            <a href="https://www.facebook.com/messages" target="_blank">Keep in Touch</a>
          </article>
          <article className="contact__option">
            <TbBrandTelegram className='contact__option-icon'/>
            <h4>Telegram</h4>
            <h5>
              +9779840299787
            </h5>
            <a href="https://t.me/sudipppppppppppppppp" target="_blank">Keep in Contact</a>
          </article>
        </div>
      {/*==========  END OF CONTACT ========== */}

      <form ref={form} onSubmit={sendEmail}>
        <input type="text" name='name' placeholder='Your Full Name' required />
        <input type="email" name='email' placeholder='Your Email' required />
        <textarea name="message"  rows="7" placeholder='Your Message'></textarea>
        <button type='submit' className='btn btn-primary'>Send Message</button>

      </form>
      </div>
    

    </section>
  )
}



export default Contact; 