import React from 'react';
import './header.css';
import CV from '../../assets/Sudip_Parajuli_CV.pdf';

const CTA = () => {
  return (
    <div className='cta'>
        <a href={CV} download className='btn'> Download CV</a>
        <a href='#portfolio' className ='btn btn-primary'> Projects</a>
    </div>
  )
}

export default CTA