import React from 'react';
import "./header.css";
import CTA from './CTA';
import ME from '../../assets/sudipppp1.png'
import HeaderSocials from './HeaderSocials';

const Header = () => {
  return (
    <header>
      <div className = "container header__container">
      <div className="wrapper">
      <div className="letters-w">
            <div className="f-letters-w">
                <span className='letterh' >H</span>
                <span className='lettere'>e</span>
                <span className='letterl' >l</span>
                <span className="letterll" >l</span>
                <span className="lettero" >o&nbsp; </span>
                <span className="letteri" >I' </span>
                <span className="lettera" >m</span>
            </div>
        </div>
        <ul className="dynamic-txts">
          <li><span>Sudip Parajuli</span></li>
          <li><span>Frontend Developer</span></li>
        </ul>
        </div>

        <CTA />
        <HeaderSocials/>

        <div className="me">
          <img src={ME} alt="me" />
        </div>
        
        <a href='#contact' className='scroll__down'> Scroll Down</a>
        </div>


    </header>
      
  )
}

export default Header