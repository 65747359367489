import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/shoe-store2.jpg";
import IMG2 from "../../assets/tindog.png";
import IMG3 from "../../assets/whack-a-mole.png";
import IMG4 from "../../assets/movieLand.png";
import IMG5 from "../../assets/search-github-users.jpg";
import IMG6 from "../../assets/drag-and-drop.png";

const data = [
  {
    id: 1,
    image: IMG1,
    title:
      "Full Stack E-Commerce Website | Made using Next.js, Redux, Strapi Headless CMS & Tailwind",
    github:
      "https://github.com/Sudip777/ecommerce-shoeStore-frontend/tree/main",
    demo: "https://ecommerce-shoe-store-frontend.vercel.app",
  },
  {
    id: 5,
    image: IMG5,
    title:
      "Search GitHub Users | Get Inspired | Made using React JS",
    github:
      "https://github.com/Sudip777/react-search-github-users",
    demo: "https://search-github-users-get-inspired.netlify.app",
  },

  {
    id: 6,
    image: IMG6,
    title:
      "Project Manager | Drag and Drop | Typescript",
    github:
      "https://github.com/Sudip777/drag-drop",
    demo: "https://sudip777.github.io/drag-drop",
  },

  {
    id: 4,
    image: IMG4,
    title:
      "MovieLand | All about Films | Made using React JS",
    github:
      "https://github.com/Sudip777/my_react_app",
    demo: "https://search-movies-movieland.netlify.app",
  },
  {
    id: 3,
    image: IMG3,
    title:
      "Whack a Mole | A Fun Game made using HTML, CSS and JavaScript",
    github:
      "https://github.com/Sudip777/whackaMole",
    demo: "https://sudip777.github.io/whackaMole/",
  },

  {
    id: 2,
    image: IMG2,
    title:
      "TinDog | Meet new and interesting dogs nearby | HTML, CSS and Bootstrap",
    github: "https://github.com/Sudip777/TinDog",
    demo: "https://sudip777.github.io/TinDog/",
  },
]

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2> Portfolio</h2>
      <div className="container portfolio__container">
        {data.map(
          ({
            id,
            title,
            github,
            demo,
            image,
          }) => {
            return (
              <article
                key={id}
                className="portfolio__item">
                <div className="portfolio__item-image">
                  <img
                    src={image}
                    alt={title}
                  />
                </div>
                <h3>{title}</h3>
                <div className="portfolio__item-cta">
                  <a
                    href={github}
                    className="btn"
                    target="_blank"
                    rel="noreferrer">
                    Github
                  </a>
                  <a
                    href={demo}
                    className="btn btn-primary"
                    target="_blank"
                    rel="noreferrer">
                    Live Demo
                  </a>
                </div>
              </article>
            );
          }
        )}
      </div>
    </section>
  );
};

export default Portfolio;
